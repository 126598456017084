<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <template v-slot:groupName>
        <div style="position: relative">
          <el-input v-model="formData.groupName" :readonly="true"></el-input>
        </div>
      </template>
      <template v-slot:PRODUCT_KEY>
        <div style="position: relative">
          <el-input v-model="formData.PRODUCT_KEY">
            <div slot="append">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                type="primary"
                @click="openRenewalDialog('PRODUCT_KEY')"
            >{{formData.PRODUCT_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
            <el-button v-if="formData.PRODUCT_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('PRODUCT_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:WEB_EDIT_PRODUCT_KEY>
        <div style="position: relative">
          <el-input v-model="formData.WEB_EDIT_PRODUCT_KEY">
            <div slot="append">
              <el-button
                  class="selectEquipmentButton"
                  v-if="cmd !== 'view'"
                  type="primary"
                  @click="openRenewalDialog('WEB_EDIT_PRODUCT_KEY')"
              >{{formData.WEB_EDIT_PRODUCT_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
              <el-button v-if="formData.WEB_EDIT_PRODUCT_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('WEB_EDIT_PRODUCT_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:task>
        <div style="position: relative">
          <el-input v-model="formData.task" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('task')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:taskDistribute>
        <div style="position: relative">
          <el-input v-model="formData.taskDistribute" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('taskDistribute')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:fileShare>
        <div style="position: relative">
          <el-input v-model="formData.fileShare" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('fileShare')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:monitoring>
        <div style="position: relative">
          <el-input v-model="formData.monitoring" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('monitoring')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:report>
        <div style="position: relative">
          <el-input v-model="formData.report" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('report')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:dataAnalysis>
        <div style="position: relative">
          <el-input v-model="formData.dataAnalysis" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('dataAnalysis')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:dataApi>
        <div style="position: relative">
          <el-input v-model="formData.dataApi" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('dataApi')"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
    </dialog-form-list>
    <renewal v-if="showRenewal" :tableRow="tableRow" :renewalType="renewalType" :is-open="isOpen" @closePage="closePage" />
    <dialog-tree
        :title="title"
        v-if="showDialogMultipleTree"
        height="400px"
        :isDefine="true"
        :defaultExpandedKeys="[1]"
        :defineConfirm="true"
        :defaultContent="defaultContent"
        @cancel="showDialogMultipleTree = false"
        @confirm="changeContactsVisible"
    >
      <template v-slot:default="{ data }">
        <div
            class="treeList"
            style="display: flex; justify-content: space-between; width: 100%"
        >
          <div>
            <el-checkbox
                class="canUseRadio"
                v-model="data.selected"
                :indeterminate="data.isIndeterminate"
                @change="changeSelected($event, data, 'selected')"
            />
            {{ data.name }}
          </div>
        </div>
      </template>
    </dialog-tree>
  </div>
</template>

<script>
import Renewal from './renewal'

export default {
  components: {
    Renewal,
  },
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      title: this.$t('accountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      currentVisibleType: null,
      cmd: this.$store.state.cmd,
      formData: {},
      isOpen: false,
      config: [
        // lang:目标账号
        {
          label: this.$t('accountPermissionManage.targetAccount'),
          prop: 'targetAccount',
          hidden: false,
          tagProps: {
            readonly:'readonly'
          }
        },
        // lang:所属部门
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.accountGroup'),
          prop: 'groupName',
        },
        // lang:产品密钥
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.productKey'),
          prop: 'PRODUCT_KEY',
        },
        // lang:编辑管理中心
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.webEditProductKey'),
          prop: 'WEB_EDIT_PRODUCT_KEY',
        },
        // lang:任务可见范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.taskVisible'),
          prop: 'task',
        },
        // lang:任务派发范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.taskDistributeVisible'),
          prop: 'taskDistribute',
        },
        // lang:文件对外分享范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.fileShareVisible'),
          prop: 'fileShare',
        },
        // lang:监控可见范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.monitoringVisible'),
          prop: 'monitoring',
        },
        // lang:报告可见范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.reportVisible'),
          prop: 'report',
        },
        // lang:数据分析范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.dataAnalysisVisible'),
          prop: 'dataAnalysis',
        },
        // lang:数据接口管理范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.dataApiVisible'),
          prop: 'dataApi',
        },
      ],
      accountPermissionData: null,
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    deactivate(type) {
      this.$element
          .showConfirm({
            content: this.$t('accountPermissionManage.deactivateConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.disabledAccount,
              data: {
                uid: this.tableRow.uid,
                type
              },
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deactivateSuccess'), 'success')
              this.formData[type] = this.$t('accountPermissionManage.notHave')
            })
          })
    },
    closePage(evt) {
      this.showRenewal = false
      if(evt === 'add') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findAccountPermission,
          data: {
            uid: this.tableRow.uid,
          },
        }).then((data) => {
          let pk = data.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
          let ti = data.secretKeyDistributionList.filter(item => item.type === 'WEB_EDIT_PRODUCT_KEY')
          if(ti.length !== 0) {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$util.format(ti[0].expirationDate)
          } else {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
        })
      }
    },
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 弹出续期窗口
    openRenewalDialog(type) {
      this.showRenewal = true
      this.renewalType = type
      this.isOpen = this.formData[type] === this.$t('accountPermissionManage.notHave')
    },
    openDialogTree(type) {
      this.currentVisibleType = type
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findCompanyTreeContacts,
        data: {
        },
      }).then((data) => {
        console.log(data)
        let res = {accountPermission: this.accountPermissionData}
        data.map(item => {
          if(res.accountPermission[visibleType] === 'ALL') {
            item.selected = true
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                item1.selected = true
              })
            }
          } else if(res.accountPermission[visibleType] === 'PART'){
            if(res.accountPermission[groupVisibleSet] !== null) {
              res.accountPermission[groupVisibleSet].forEach(item2 => {
                if(item2.uid === item.uid) {
                  item.selected = true
                  if(item.children && item.children.length !== 0) {
                    item.children.map(item3 => {
                      item3.selected = true
                    })
                  }
                }
              })
            }
            if(res.accountPermission[accountVisibleSet] !== null) {
              res.accountPermission[accountVisibleSet].forEach(item4 => {
                if(item.children && item.children.length !== 0) {
                  item.children.map(item5 => {
                    if(item4.uid === item5.uid) {
                      item5.selected = true
                    }
                  })
                }
              })
              let flag = false
              item.children.forEach(item6 => {
                if(! item6.selected) {
                  flag = true
                }
              })
              if(flag) {
                item.isIndeterminate = true
                item.selected = false
              } else {
                item.isIndeterminate = false
                item.selected = true
              }
            }
          }
        })
        data.map(item => {
          if(!item.selected) {
            item.selected = false
          }
          if(item.children && item.children.length !== 0) {
            item.children.map(item1 => {
              if(!item1.selected) {
                item1.selected = false
              }
            })
          }
        })
        console.log('after', data)
        this.defaultContent = data
        this.showDialogMultipleTree = true
      })
    },
    changeSelected(evt, data, key) {
      console.log(evt, data, key)
      console.log(this.defaultContent)
      if(data.nodeType === 'GROUP') {
        this.defaultContent.map(item => {
          if(item.uid === data.uid) {
            item.selected = evt
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                item1.selected = evt
              })
            }
          }
        })
      }
      if(data.nodeType === 'ACCOUNT') {
        this.defaultContent.map(item => {
          if(item.children && item.children.length !== 0) {
            item.children.map(item1 => {
              if(item1.uid === data.uid) {
                item1.selected = evt
              }
            })
          }
        })
      }
      let flag = false
      this.defaultContent.map(item => {
        item.children.forEach(item2 => {
          if (!item2.selected) {
            flag = true
          }
        })
        if (flag) {
          item.isIndeterminate = true
          item.selected = false
        } else {
          item.isIndeterminate = false
          item.selected = true
        }
        if(!evt && data.nodeType === 'GROUP') {
          item.isIndeterminate = false
        }
      })
    },

    changeContactsVisible() {
      let type = this.currentVisibleType
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      this.accountPermissionData[accountVisibleSet] = []
      this.accountPermissionData[groupVisibleSet] = []
      this.defaultContent.forEach(item => {
        if(! item.selected) {
          this.accountPermissionData[visibleType] = 'PART'
          if(item.children && item.children.length !== 0) {
            item.children.forEach(item1 => {
              if(item1.selected) {
                this.accountPermissionData[accountVisibleSet].push({uid: item1.uid, name: item1.name})
              }
            })
          }
        } else {
          this.accountPermissionData[groupVisibleSet].push({uid: item.uid, name: item.name})
        }
      })
      if(this.accountPermissionData[groupVisibleSet].length === 0) {
        this.accountPermissionData[groupVisibleSet]= null
      }
      if(this.accountPermissionData[accountVisibleSet].length === 0) {
        this.accountPermissionData[accountVisibleSet] = null
      }
      if(this.accountPermissionData[visibleType] === 'ALL') {
        this.accountPermissionData[groupVisibleSet]= null
        this.accountPermissionData[accountVisibleSet] = null
      }
      console.log(this.accountPermissionData)
      this.$nextTick(() => {
        this.formData.task = this.getVisibleText('task')
        this.formData.taskDistribute = this.getVisibleText('taskDistribute')
        this.formData.fileShare = this.getVisibleText('fileShare')
        this.formData.monitoring = this.getVisibleText('monitoring')
        this.formData.report = this.getVisibleText('report')
        this.formData.dataAnalysis = this.getVisibleText('dataAnalysis')
        this.formData.dataApi = this.getVisibleText('dataApi')
      })
      this.showDialogMultipleTree = false
    },

    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.targetAccount = this.tableRow.name + ' ' + this.tableRow.accountId
          this.formData.groupName = this.tableRow.group.name
          let pk = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
          let ti = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'WEB_EDIT_PRODUCT_KEY')
          if(ti.length !== 0) {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$util.format(ti[0].expirationDate)
          } else {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
          this.accountPermissionData = this.tableRow.accountPermission

          this.formData.task = this.getVisibleText('task')
          this.formData.taskDistribute = this.getVisibleText('taskDistribute')
          this.formData.fileShare = this.getVisibleText('fileShare')
          this.formData.monitoring = this.getVisibleText('monitoring')
          this.formData.report = this.getVisibleText('report')
          this.formData.dataAnalysis = this.getVisibleText('dataAnalysis')
          this.formData.dataApi = this.getVisibleText('dataApi')

        }
      })
    },

    getVisibleText(type) {
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      let aaa = ''
      if(this.accountPermissionData[visibleType] === 'PART') {
        if(this.accountPermissionData[groupVisibleSet] !== null) {
          this.accountPermissionData[groupVisibleSet].forEach(item => aaa += item.name + '、')
        }
        if(this.accountPermissionData[accountVisibleSet] !== null) {
          this.accountPermissionData[accountVisibleSet].forEach(item => aaa += item.name + '、')
        }
      } else if(this.accountPermissionData[visibleType] === 'ALL' || this.accountPermissionData[visibleType] === null) {
        aaa += this.$t('accountPermissionManage.allPermission') + '、'
      }
      return aaa.substr(0, aaa.length - 1)
    },

    // 取消
    cancel() {
      this.$emit('closePage', 'cancel1')
    },
    // 确认
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateVisible,
        data: {
          uid: this.tableRow.uid,
          accountPermissionData: this.accountPermissionData
        },
        headers: {product_version: 'PRACTICAL_TRAINING_EDUCATION'}
      }).then((data) => {
        this.$emit('closePage', 'confirm')
      })
    }
  },
}
</script>
